import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Link/Link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Stack/Stack.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/SvgIcon/SvgIcon.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Typography/Typography.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/auth/(coin)/CoinGradient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/SubmitButton/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/TwoSidesLayout/Header.tsx");
